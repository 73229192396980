import { FC, useEffect, useState } from 'react';
import { checkEmailMask } from '../../utils/validators';
import ModalWrap from '../common/modal-wrap';
import { CloseCrossBtn } from '../icons/close-cross-btn';
import Input from '../common/input';
import { CustomSelect } from '../common/select';
import { DatePickerCustom } from '../common/datepicker';
import { Switch } from '../common/switch';
import Button from '../common/button';
import { timezoneList } from '../../assets/timezone';
import { PhoneInputWithMask } from '../common/phone-input-with-mask';
import { IClient } from '../../store/types';
import { isValidPhoneNumber } from 'libphonenumber-js/min';

interface ICreateClientProps {
  open: boolean;
  close: () => void;
  save: (form: IFormDataClient) => void;
  clientData?: IClient;
}

export interface IFormDataClient {
  name: string;
  date: any;
  phone: string;
  email: string;
  tz: string;
  tg: string;
  vk: string;
  info: string;
  active: boolean;
}
const defaultClient = {
  name: '',
  date: '',
  phone: '',
  email: '',
  tz: '(GMT +03:00) Москва',
  tg: '',
  vk: '',
  info: '',
  active: true,
};

export const CreateClientForm: FC<ICreateClientProps> = ({ open, close, save, clientData }) => {
  const [formDataClient, setFormDataClient] = useState<IFormDataClient>(defaultClient);
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPhone, setErrorPhone] = useState('');
  const [errorClient, setErrorClient] = useState('');
  const [errorTZ, setErrorTZ] = useState('');

  useEffect(() => {
    if (clientData) {
      setFormDataClient((state) => ({
        ...state,
        name: clientData.full_name,
        date: clientData.birthday,
        phone: clientData.phone,
        email: clientData.email,
        tz: clientData.time_zone,
        tg: clientData.telegram,
        vk: clientData.vk,
        info: clientData.note,
        active: clientData.is_active,
      }));
    } else {
      setFormDataClient(defaultClient);
    }
  }, [clientData, open]);

  const onBlurEmail = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value === '') return;
    const isEmail = checkEmailMask(e.target.value);
    if (!isEmail) {
      setErrorEmail('Неверный формат Email');
    } else {
      setErrorEmail('');
    }
  };
  const onBlurClient = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setErrorClient('Заполните поле Клиент');
    } else {
      setErrorClient('');
    }
  };
  const handleSave = () => {
    const tzListName = timezoneList.map((i) => i.name).includes(formDataClient.tz);

    if (
      formDataClient.name === '' ||
      !tzListName
      // || !isValidPhoneNumber(formDataClient.phone)
    ) {
      setErrorClient(formDataClient.name === '' ? 'Заполните поле Клиент' : '');
      setErrorTZ(!tzListName ? 'Выберите из списка' : '');
      // setErrorPhone(!isValidPhoneNumber(formDataClient.phone) ? 'Неверный формат телефона' : '');
      return;
    }
    if (formDataClient.email !== '') {
      const isEmail = checkEmailMask(formDataClient.email);
      if (!isEmail) {
        setErrorEmail('Неверный формат Email');
        return;
      }
    }
    save(formDataClient);
    handleClose();
  };

  const handleClose = () => {
    setErrorClient('');
    setErrorEmail('');
    setErrorTZ('');
    setErrorPhone('');
    setFormDataClient(defaultClient);
    close();
  };

  return (
    <ModalWrap isShow={open} className="!p-0 md:p-[15px]">
      <div className="think-scroll flex flex-col gap-3.5 lg:gap-5 w-full md:max-w-[60%] lg:max-w-[978px] h-full md:h-auto md:max-h-[90%] md:rounded-[20px] lg:rounded-[40px] p-5 lg:p-8 bg-white">
        <div className="w-full flex justify-between items-center">
          <h2 className="font-comfortaa lg:text-2xl text-[#111026] font-bold">
            {!clientData && 'Создание клиента'}
          </h2>
          <button className="cursor-pointer" onClick={() => handleClose()}>
            <CloseCrossBtn width={20} height={20} />
          </button>
        </div>
        <div className="flex flex-col gap-3.5 lg:gap-5 overflow-y-auto">
          <Input
            classNameLabel="text-xs text-[#585858]"
            className="!h-[40px] lg:!h-[48px] placeholder-[#111026]"
            label="Клиент (обязательное поле)"
            value={formDataClient.name}
            onChange={(e) => {
              if (e.target.value.length > 50) {
                return;
              }
              setFormDataClient({ ...formDataClient, name: e.target.value });
            }}
            placeholder="Введите ФИО или псевдоним"
            error={errorClient}
            onBlur={onBlurClient}
            showError={false}
          />

          <div className="w-full flex flex-col lg:flex-row lg:items-center gap-3.5 lg:gap-[30px]">
            <div className="w-full lg:max-w-[320px]">
              <CustomSelect
                label="Часовой пояс клиента"
                items={timezoneList.map((el) => el.name)}
                selected={formDataClient.tz}
                onChange={(a) => {
                  setFormDataClient({ ...formDataClient, tz: a });
                  setErrorTZ('');
                }}
                helper="Выберите часовой пояс клиента, чтобы время встречи отображалось правильно для него и вас."
                error={errorTZ}
                showError={false}
                classNameUlList="!max-h-[168px]"
              />
            </div>
          {/*  <div className="w-full lg:max-w-[220px]">*/}
          {/*    <DatePickerCustom*/}
          {/*      date={formDataClient.date}*/}
          {/*      label="Дата рождения"*/}
          {/*      setDate={(a) => setFormDataClient({ ...formDataClient, date: a })}*/}
          {/*      disabled*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <PhoneInputWithMask*/}
          {/*    classNameWrap="lg:max-w-[220px]"*/}
          {/*    label="Телефон"*/}
          {/*    classNameLabel="text-xs text-[#585858]"*/}
          {/*    selected={formDataClient.phone}*/}
          {/*    disabled*/}
          {/*    onChange={(e) => {*/}
          {/*      setFormDataClient({ ...formDataClient, phone: e });*/}
          {/*      // if (isValidPhoneNumber(e)) {*/}
          {/*      //   setErrorPhone('');*/}
          {/*      // }*/}
          {/*    }}*/}
          {/*    // onBlur={(val) => {*/}
          {/*    //   if (!isValidPhoneNumber(val)) {*/}
          {/*    //     setErrorPhone('Неверный формат телефона')*/}
          {/*    //   }*/}
          {/*    // }}*/}
          {/*    // error={errorPhone}*/}
          {/*    // showError={false}*/}
          {/*  />*/}
            <div className="lg:pt-[21px]">
              <Switch
                checked={formDataClient.active}
                setChecked={(a) => {
                  setFormDataClient({ ...formDataClient, active: a });
                }}
                label="Клиент Активный"
              />
            </div>
          </div>

          {/*<div className="w-full flex flex-col lg:flex-row items-center lg:items-end justify-between gap-3.5 lg:gap-[30px]">*/}
          {/*  <Input*/}
          {/*    type="email"*/}
          {/*    value={formDataClient.email}*/}
          {/*    error={errorEmail}*/}
          {/*    showError={false}*/}
          {/*    onBlur={onBlurEmail}*/}
          {/*    autoComplete={'email'}*/}
          {/*    onChange={(e) => setFormDataClient({ ...formDataClient, email: e.target.value })}*/}
          {/*    classNameWrap="lg:max-w-[284px]"*/}
          {/*    classNameLabel="text-xs text-[#585858]"*/}
          {/*    label="Email"*/}
          {/*    placeholder="Введите электронный адрес"*/}
          {/*    disabled*/}
          {/*  />*/}
          {/*  <Input*/}
          {/*    value={formDataClient.tg}*/}
          {/*    onChange={(e) => setFormDataClient({ ...formDataClient, tg: e.target.value })}*/}
          {/*    classNameWrap="lg:max-w-[284px]"*/}
          {/*    classNameLabel="text-xs text-[#585858]"*/}
          {/*    label="Telegram"*/}
          {/*    placeholder="Введите ник начиная с @"*/}
          {/*    disabled*/}
          {/*  />*/}
          {/*  <Input*/}
          {/*    value={formDataClient.vk}*/}
          {/*    onChange={(e) => setFormDataClient({ ...formDataClient, vk: e.target.value })}*/}
          {/*    classNameWrap="lg:max-w-[284px]"*/}
          {/*    classNameLabel="text-xs text-[#585858]"*/}
          {/*    label="VK"*/}
          {/*    placeholder="Введите ссылку"*/}
          {/*    disabled*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="w-full flex flex-col gap-[10px]">
            <label className="text-xs text-[#585858]">Дополнительная информация</label>
            <textarea
              value={formDataClient.info}
              onChange={(e) => {
                if (e.target.value.length > 210) {
                  return;
                }
                setFormDataClient({ ...formDataClient, info: e.target.value });
              }}
              className="resize-none p-[10px_15px] border border-greyBase focus:border-2 rounded-[10px] text-base text-blackBase placeholder-[#111026] min-h-[170px] md:min-h-[150px] lg:min-h-[125px]"
              placeholder="Введите текст, например, имеет судимость, имеет определенное заболевание, принимает антидепресанты, имеет психиатрический диагноз, уже был в терапии и т.п."
            />
          </div>
        </div>
        <Button
          className="!ml-auto max-w-[160px] !text-base py-[6px] px-2.5"
          type="black"
          onClick={handleSave}>
          Сохранить
        </Button>
      </div>
    </ModalWrap>
  );
};
