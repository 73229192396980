import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { IConsultation } from '../../../store/types';
import { FormDataConsultations } from '../../../pages/Consultations';
import {
  getMonday,
  addDateBy,
  lessDateBy,
  weekDates,
  getDate,
  getTime,
} from '../../../utils/getTime';
import { ArrowLeft } from '../../icons/arrow-left';
import Button from '../../common/button';
import dayjs from 'dayjs';

interface Props {
  close: () => void;
  openCreateForm: () => void;
  clients: { id: number; name: string }[];
  consultations: IConsultation[];
  formData: FormDataConsultations;
  setFormData: Dispatch<SetStateAction<FormDataConsultations>>;
  handleChangeCons: (id: number) => void;
}

const HEIGHT_HOUR = 74;

export const Schedule: FC<Props> = ({
                                      close,
                                      openCreateForm,
                                      clients,
                                      consultations,
                                      handleChangeCons,
                                    }) => {
  const [monday, setMonday] = useState(getMonday(new Date()));
  const [consultationList, setConsultationList] = useState<
    {
      id: number;
      client_entity: string;
      date: string;
      datetime_start: string;
      datetime_end: string;
      client_timezone: string;
      code: string;
      link: string;
    }[]
  >([]);
  const [copy, setCopy] = useState(false);

  const scrollToRef = useRef<HTMLDivElement | null>(null);
  const scrollToCardNum = 12;

  useEffect(() => {
    // If `scrollToRef` points to an element, then scroll it into view.
    if (scrollToRef.current) {
      scrollToRef?.current?.scrollIntoView({
        block: 'nearest',
        inline: 'start',
      });
    }
  }, []);

  useEffect(() => {
    if (!copy) return;

    const timer = setTimeout(() => {
      setCopy(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [copy]);

  useEffect(() => {
    if (consultations) {
      setConsultationList(consultations);
    }
  }, [consultations]);

  const getNextWeek = () => {
    const newMonday = addDateBy(monday, 7);
    setMonday(newMonday);
  };
  const getLastWeek = () => {
    const newMonday = lessDateBy(monday, 7);
    setMonday(newMonday);
  };

  return (
    <>
      <div className="w-full h-max flex flex-col overflow-x-auto">
        <div className="w-full flex flex-col sm:flex-row gap-[28px] justify-between mb-[28px]">
          <div className="flex items-center gap-5">
            <button onClick={() => close()} className="group">
              <ArrowLeft className="fill-blackBase group-hover:fill-redBase" />
            </button>
            <h1 className="font-comfortaa font-bold text-2xl">Календарь встреч</h1>
          </div>
          <Button
            type={'primary'}
            onClick={() => openCreateForm()}
            className="w-full sm:w-max h-10 !p-[10px_30px] !text-base flex items-center gap-5 ml-auto">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 4.10742V15.8925M4.10744 9.99998H15.8926"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Создать встречу
          </Button>
        </div>
        <div className="w-full flex flex-col gap-[30px]">
          <div className="w-fit min-w-[100%] flex items-center gap-[20px] justify-between">
            <Button
              type="grey"
              className="!p-[10px] max-w-[40px] h-[40px] flex items-center group"
              onClick={() => getLastWeek()}>
              <svg
                className="fill-blackBase group-hover:fill-white"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.88981 10.4417C2.77277 10.3245 2.70703 10.1657 2.70703 10C2.70703 9.83441 2.77277 9.67556 2.88981 9.55837L7.88981 4.55837C7.94703 4.49696 8.01603 4.44771 8.0927 4.41355C8.16936 4.37939 8.25213 4.36102 8.33605 4.35954C8.41996 4.35806 8.50332 4.3735 8.58115 4.40493C8.65897 4.43637 8.72966 4.48315 8.78901 4.5425C8.84836 4.60185 8.89515 4.67255 8.92658 4.75037C8.95802 4.82819 8.97345 4.91155 8.97197 4.99547C8.97049 5.07939 8.95213 5.16215 8.91796 5.23882C8.8838 5.31548 8.83455 5.38448 8.77315 5.4417L4.83981 9.37503L16.6648 9.37503C16.8306 9.37503 16.9895 9.44088 17.1068 9.55809C17.224 9.6753 17.2898 9.83427 17.2898 10C17.2898 10.1658 17.224 10.3248 17.1068 10.442C16.9895 10.5592 16.8306 10.625 16.6648 10.625L4.83981 10.625L8.77315 14.5584C8.83455 14.6156 8.88381 14.6846 8.91797 14.7613C8.95213 14.8379 8.97049 14.9207 8.97197 15.0046C8.97345 15.0885 8.95802 15.1719 8.92658 15.2497C8.89515 15.3275 8.84836 15.3982 8.78901 15.4576C8.72966 15.5169 8.65897 15.5637 8.58115 15.5951C8.50332 15.6266 8.41997 15.642 8.33605 15.6405C8.25213 15.639 8.16937 15.6207 8.0927 15.5865C8.01603 15.5524 7.94703 15.5031 7.88981 15.4417L2.88981 10.4417Z"
                />
              </svg>
            </Button>
            <div className="w-full flex max-w-[728px]">
              <div
                className={classNames(
                  'w-full max-w-[104px] p-[5px_35px] flex flex-col items-center gap-[5px] rounded-[15px_0px_0px_15px] border border-[#C7C7C799]',
                  {
                    ['!border-[#C00000]']: new Date().toDateString() === monday.toDateString(),
                  }
                )}>
                <p className="font-medium text-base">{dayjs(monday).format('DD.MM')}</p>
                <p className="text-sm text-[#585858]">{dayjs(monday).format('dd')}</p>
              </div>
              {weekDates(monday).map((el, idx) => (
                <div
                  key={idx}
                  className={classNames(
                    'w-full max-w-[104px] p-[5px_35px] flex flex-col items-center gap-[5px] last:rounded-[0px_15px_15px_0px] border border-[#C7C7C799]',
                    {
                      ['!border-[#C00000]']:
                      new Date().toDateString() === new Date(el).toDateString(),
                    }
                  )}>
                  <p
                    className={classNames('font-medium text-base', {
                      'text-redBase': new Date().toDateString() === new Date(el).toDateString(),
                    })}>
                    {dayjs(new Date(el)).format('DD.MM')}
                  </p>
                  <p className="text-sm text-[#585858]">{dayjs(new Date(el)).format('dd')}</p>
                </div>
              ))}
            </div>
            <Button
              type="grey"
              className="!p-[10px] max-w-[40px] h-[40px] rotate-180 flex items-center group mr-5 md:mr-0"
              onClick={() => getNextWeek()}>
              <svg
                className="fill-blackBase group-hover:fill-white"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.88981 10.4417C2.77277 10.3245 2.70703 10.1657 2.70703 10C2.70703 9.83441 2.77277 9.67556 2.88981 9.55837L7.88981 4.55837C7.94703 4.49696 8.01603 4.44771 8.0927 4.41355C8.16936 4.37939 8.25213 4.36102 8.33605 4.35954C8.41996 4.35806 8.50332 4.3735 8.58115 4.40493C8.65897 4.43637 8.72966 4.48315 8.78901 4.5425C8.84836 4.60185 8.89515 4.67255 8.92658 4.75037C8.95802 4.82819 8.97345 4.91155 8.97197 4.99547C8.97049 5.07939 8.95213 5.16215 8.91796 5.23882C8.8838 5.31548 8.83455 5.38448 8.77315 5.4417L4.83981 9.37503L16.6648 9.37503C16.8306 9.37503 16.9895 9.44088 17.1068 9.55809C17.224 9.6753 17.2898 9.83427 17.2898 10C17.2898 10.1658 17.224 10.3248 17.1068 10.442C16.9895 10.5592 16.8306 10.625 16.6648 10.625L4.83981 10.625L8.77315 14.5584C8.83455 14.6156 8.88381 14.6846 8.91797 14.7613C8.95213 14.8379 8.97049 14.9207 8.97197 15.0046C8.97345 15.0885 8.95802 15.1719 8.92658 15.2497C8.89515 15.3275 8.84836 15.3982 8.78901 15.4576C8.72966 15.5169 8.65897 15.5637 8.58115 15.5951C8.50332 15.6266 8.41997 15.642 8.33605 15.6405C8.25213 15.639 8.16937 15.6207 8.0927 15.5865C8.01603 15.5524 7.94703 15.5031 7.88981 15.4417L2.88981 10.4417Z"
                />
              </svg>
            </Button>
          </div>
          <div className="w-fit grid grid-cols-[66px_104px_104px_104px_104px_104px_104px_104px] overflow-y-scroll h-[400px] no-scrollbar border-[#C7C7C799]">
            <div className="grid h-fit grid-rows-24">
              {Array.from({ length: 24 }, (_, i) => (
                <div
                  key={i}
                  ref={i === scrollToCardNum ? scrollToRef : null}
                  className="leading-none h-[74px] flex justify-start relative">
                  <span>{`${i < 10 ? `0${i}` : i}:00`}</span>
                </div>
              ))}
            </div>
            {[monday].concat(weekDates(monday)).map((el, idx) => (
              <div
                className="w-full max-w-[104px] p-[5px_35px] flex flex-col relative items-center gap-[5px] border border-[#C7C7C799] break-all"
                key={idx}>
                {consultationList
                  .filter((item) => getDate(new Date(item.date)) === getDate(new Date(el)))
                  .map((consul) => {
                    const client = clients.find(
                      (el) => `/api/v1/clients/${el.id}` === consul.client_entity
                    );
                    const start = getTime(new Date(consul.datetime_start)).split(':');
                    const end = getTime(new Date(consul.datetime_end)).split(':');
                    const difference =
                      Number(end[0]) * 60 +
                      Number(end[1]) -
                      (Number(start[0]) * 60 + Number(start[1]));
                    const clamp = difference / 60
                    const name = clamp <= 0.5
                      ? client?.name.slice(0, 9).trim() + '...'
                      : clamp <= 1
                        ? client?.name.slice(0, 16).trim() + '...'
                        : clamp <= 1.5
                          ? client?.name.slice(0, 32).trim() + '...'
                          : client?.name
                    return (
                      <div
                        key={consul.id}
                        onClick={() => handleChangeCons(consul.id)}
                        className={classNames(
                          'absolute w-full max-w-[100px] p-[10px_5px] bg-[#DEC05E] text-sm text-blackBase font-semibold rounded-[5px] flex items-center text-center justify-center cursor-pointer',
                          {
                            'z-[1]': client,
                          }
                        )}
                        style={{
                          top: `${
                            Number(start[0]) * HEIGHT_HOUR + (Number(start[1]) * HEIGHT_HOUR) / 60
                          }px`,
                          height: `${(difference * HEIGHT_HOUR) / 60}px`,
                        }}
                        title={client?.name}>
                        {name}
                      </div>
                    );
                  })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
